<template>
    <div
        class="app-notification"
        :class="{'not-read': !isRead}"
        @click="onClick()"
    >
        <div
            v-if="showClose"
            class="app-notification-close"
            @click.stop="onClose()"
        >
            <i></i>
        </div>
        <div
            v-else
            class="app-notification-status"
            @click.stop="onStatus()"
        ></div>

        <div class="app-notification-icon">
            <img :src="item.icon">
        </div>
        <div class="app-notification-content">
            <div
                class="app-notification-title"
                v-html="item.title"
            >
            </div>
            <div
                class="app-notification-text"
                v-html="item.message"
            >
            </div>
        </div>
        <div class="app-notification-date">
            {{ date }}
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { texts } from "@/texts/data";

    export default {
        name: "Notification",
        props: {
            item: {
                type: Object
            },
            showClose: {
                type: Boolean,
                default: false
            },
            lang: String,
        },
        methods: {
            onClick() {
                this.$emit(
                    'click',
                    this.item
                )
            },
            onStatus() {
                this.$emit(
                    'changeStatus',
                    this.item
                )
            },
            onClose() {
                this.$emit(
                    'close',
                    this.item
                )
            }
        },
        computed: {
            isRead() {
                return this.item.status === 2;
            },
            date() {
                const format = 'DD.MM.YYYY';
                const today = moment()
                    .format(format);
                const date = moment
                    .unix(this.item.sand_at)
                    .format(format);
                if (today === date) {

                    return this.texts.group_today
                }
                else {
                    return date;
                }
            }
        },
        created() {
            this.texts = texts[this.lang];
            Object.freeze(this.texts);
        }
    }
</script>

<style
    lang="scss"
    scoped
>
    .app-notification {
        position: relative;
        display: flex;
        cursor: pointer;
        width: 100%;
        align-items: center;
        height: $app-notification-height;
        background: $app-notification-background;
        margin: $app-notification-margin;
        padding: $app-notification-padding;
        border-radius: $app-notification-radius;
        color: $app-notification-color;

        transition: $app-transition-all;

        font-size: 14px;
        line-height: 1;

        &:hover {
            background: $app-notification-background-hover;
        }

        &.not-read {
            background: $app-notification-background-not-read;

            &:hover {
                background: $app-notification-background-not-read-hover;
            }

            .app-notification-status {
                background: transparent;
            }
        }

        &-icon {
            width: 54px;
            min-width: 54px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
            }
        }

        &-content {
            flex-grow: 1;
            overflow: hidden;
            padding: 0 12px;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;


        }

        &-status {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 10px;
            height: 10px;
            border: solid 2px #53a385;
            border-radius: 50%;
            background: #53a385;
        }

        &-enter-active {
            transform: translate(100%, 0);
            opacity: 0.5;
        }

        &-leave-active {
            transform: translate(100%, 0);
            opacity: 0.5;
        }

        &-date {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        &-close {
            position: absolute;
            right: 10px;
            top: 10px;

            i {
                display: block;
                position: relative;
                width: 20px;
                height: 20px;

                &::after,
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: $app-background;
                    top: calc(50% - 1px);
                    left: 0;
                }

                &::after {
                    transform: rotate(-45deg);
                }

                &::before {
                    transform: rotate(45deg);
                }
            }
        }
    }
</style>
<style lang="scss">
    .app-notification {
        &-content {
            ul {
                margin: 10px 0;
                padding: 0 0 0 10px;
            }
        }
    }
</style>