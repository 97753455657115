<template>
    <button @click="onClick">
        <i :class="{'app-show': !!number}">{{ number > 99 ? '99+' : number }}</i>
        <img
            :class="{'app-animation': !!number}"
            :src="`${imgUrl}/2.svg`"
        >
    </button>
</template>

<script>
    export default {
        name: "RoundButton",
        props: {
            number: {
                type: Number,
                default: 0
            },
            imgUrl: {
                type: String,
                default: '/assets/'
            }
        },
        methods: {
            onClick() {
                this.$emit('click')
            }
        }
    }
</script>

<style
    scoped
    lang="scss"
>
    button {
        width: $app-round-button-size;
        height: $app-round-button-size;
        border-radius: 50%;
        border: none 0;
        background: none;
        outline: none 0;
        transition: $app-transition-all;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        i {
            position: absolute;
            right: 0;
            top: 0;
            background: $app-accent-color;
            border-radius: 50%;
            font-size: 10px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $app-content-color-invert;
            transform: scale(0);
            font-style: normal;

            &.app-show {
                transform: scale(1);
            }
        }

        img {
            width: 60%;
            display: inline-block;

            &.app-animation {
                animation: 2s infinite buttonAnimate linear;
            }
        }

        &.app {
            &-active {
                box-shadow: $app-shadow-hide;

                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }



            &-loading {
                animation: 1s linear 0s infinite alternate loading;
            }

            &-hide {
                transform: scale(0);
            }

            &-error {
                box-shadow: inset 0 0 10px $app-shadow-color-error;
            }
        }
    }

    @keyframes loading {
        0% {
            box-shadow: inset 0 0 10px $app-shadow-color;
        }
        50% {
            box-shadow: inset 0 0 10px $app-shadow-color-hide;
        }
        100% {
            box-shadow: inset 0 0 10px $app-shadow-color;
        }
    }

    @keyframes buttonAnimate {
        0% {
            transform-origin: center top;
            transform: rotate(0deg);
        }
        10% {
            transform-origin: center top;
            transform: rotate(20deg);
        }
        20% {
            transform-origin: center top;
            transform: rotate(0deg);
        }
        30% {
            transform-origin: center top;
            transform: rotate(-20deg);
        }
        40% {
            transform-origin: center top;
            transform: rotate(0deg);
        }
        100%{
            transform-origin: center top;
            transform: rotate(0deg);
        }
    }
</style>