export const texts = {
    ru: {
        title: 'Центр уведомлений',
        tab_all: 'Все',
        tab_notifications: 'Уведомления',
        tab_calendar: 'Календарь',
        tab_publications: 'Публикации',
        group_today: 'За сегодня',
        group_earlier: 'Ранее',
        open_more: 'Открыть больше'
    },
    ua: {
        title: 'Центр сповіщень',
        tab_all: 'Усе',
        tab_notifications: 'Сповіщення',
        tab_calendar: 'Календар',
        tab_publications: 'Публікації',
        group_today: 'За сьогодні',
        group_earlier: 'Раніше',
        open_more: 'Відкрити більше'
    }
};