<template>
    <button @click="$emit('click')"></button>
</template>

<script>
    export default {
        name: "CloseButton"
    }
</script>

<style
    scoped
    lang="scss"
>
    button {
        border: none 0;
        background: none;
        outline: none;
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        transition: $app-transition-all;
        transform: rotate(45deg) scale(1);
        cursor: pointer;

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            background: $app-content-color;
            width: 60%;
            height: 2px;
            left: 50%;
            top: 50%;
            transition: $app-transition-all;

        }

        &::after {
            transform: translate(-50%, -50%) rotate(0deg);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &.hide {
            transform: rotate(45deg) scale(0);
        }
    }
</style>