import Vue from 'vue'
import App from './App.vue'
import VueCookie from 'vue-cookie';

Vue.config.productionTip = false;

Vue.use(VueCookie);

const mountEl = document.querySelector('#app');

new Vue({
    render: createElement => {
        const context = {
            props: { ...mountEl.dataset },
        };
        return createElement(
            App,
            context
        );
    }
}).$mount('#app')
