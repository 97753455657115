<template>
    <div class="app-wrapper">
        <div class="app-inner">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Wrapper"
    }
</script>

<style
    scoped
    lang="scss"
>
    .app {
        &-wrapper {
            color: $app-content-color;
            width: $app-round-button-size;
            height: $app-round-button-size;
            border-radius: $app-round-button-size/2;

            overflow: hidden;
            transition: all 0.25s linear 0s;
            background: $app-background;

            box-shadow: $app-shadow;

            position: fixed;

            right: $app-space;
            bottom: $app-space;


            @media screen and (max-width: 500px) {
                width: auto;
                left: calc(100% - #{$app-round-button-size + $app-space-mobile});
                right: $app-space-mobile;
                bottom: $app-space-mobile;
            }

            &.active {
                width: $app-wrapper-width;
                height: calc(100vh - #{$app-round-button-margin*2});
                box-shadow: $app-shadow;
                border-radius: $app-wrapper-radius;

                .app-inner {
                    opacity: 1;
                    transition: all 0.25s linear 0.25s;
                }
                @media screen and (max-width: 500px) {
                    height: 100%;
                    width: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        &-inner {
            width: $app-wrapper-width;
            transition: all 0.25s linear 0s;
            opacity: 0;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
</style>