<template>
    <div>
        <div
            class="app-overlay"
            :class="{'app-overlay-show': isOpened}"
            @click="onToggleOpen()"
        ></div>
        <div
            id="app-notifications"
            :class="{'open': isOpened}"
        >
            <Wrapper
                :class="{
                    'active': isOpened,
                }"
            >
                <template v-if="data">
                    <div class="app-header">
                        <div class="app-header-title">{{ texts.title }}</div>
                        <div class="app-header-buttons">


                            <button
                                @click="onTabClick('all')"
                                class="app-tab-button"
                                :class="{
                                    'active': activeTab === 'all'
                                }"
                            >
                                <i>
                                    <img
                                        alt=""
                                        :src="`${ imgUrl }/1.svg`"
                                    >
                                </i>
                                <span>{{ texts.tab_all}} </span>
                            </button>
                            <button
                                @click="onTabClick(1)"
                                class="app-tab-button"
                                :class="{
                                    'active': activeTab === 1
                                }"
                            >
                                <i>
                                    <img
                                        alt=""
                                        :src="`${ imgUrl }/2.svg`"
                                    >
                                    <b :class="{'app-show': !!counts.screen_1}">
                                        {{ counts.screen_1 > 99 ? '99+' : counts.screen_1 }}
                                    </b>
                                </i>
                                <span>{{ texts.tab_notifications}} </span>
                            </button>
                            <button
                                @click="onTabClick(3)"
                                class="app-tab-button"
                                :class="{
                                    'active': activeTab === 3
                                }"
                            >
                                <i>
                                    <img
                                        alt=""
                                        :src="`${ imgUrl }/3.svg`"
                                    >
                                    <b :class="{'app-show': !!counts.screen_3}">
                                        {{ counts.screen_3 > 99 ? '99+' : counts.screen_3 }}
                                    </b>
                                    <span>
                                        {{ currentDate }}
                                    </span>
                                </i>
                                <span>{{ texts.tab_calendar}} </span>
                            </button>
                            <button
                                @click="onTabClick(2)"
                                class="app-tab-button"
                                :class="{
                                    'active': activeTab === 2
                                }"
                            >
                                <i>
                                    <img
                                        alt=""
                                        :src="`${imgUrl}/4.svg`"
                                    >
                                    <b :class="{'app-show': !!counts.screen_2}">
                                        {{ counts.screen_2 > 99 ? '99+' : counts.screen_2 }}
                                    </b>
                                </i>
                                <span>{{ texts.tab_publications}} </span>
                            </button>
                        </div>
                    </div>
                    <div
                        class="app-content"
                        @scroll="onScrollContent"
                    >
                        <div
                            v-for="group in notificationsGrouped"
                            class="app-group"
                            v-bind:key="group.tab"
                        >
                            <template v-if="group.items.length">
                                <div class="app-group-title">
                                    {{group.title}}
                                    <i
                                        class="app-group-status"
                                        @click="setReadGroup(group)"
                                    ></i>
                                </div>
                                <div class="app-group-content">
                                    <transition-group
                                        tag="div"
                                        class=""

                                        enter-active-class="app-notification-to-app-enter-active"
                                        leave-active-class="app-notification-to-app-leave-active"
                                    >
                                        <Notification
                                            v-for="(item) in group.items"
                                            :item="item"
                                            :key="item.source_id"
                                            :lang="lang"
                                            @click="onNotificationClick"
                                            @changeStatus="onStatusChange"
                                        />
                                    </transition-group>
                                </div>
                                <div
                                    class="app-more"
                                    v-if="activeTab==='all'"
                                >
                                    <button @click="onTabClick(group.tab)">{{ texts.open_more }}</button>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <CloseButton
                    class="app-close"
                    :class="{
                    'hide': !isOpened,
                }"
                    @click="onToggleOpen"
                />
            </Wrapper>
            <RoundButton
                :number="newNotificationsCount"
                :img-url="imgUrl"
                class="app-open"
                :class="{
                'app-hide': isOpened,
                'app-loading': isLoading
            }"
                @click="onToggleOpen"
            />
            <transition-group
                tag="div"
                class="app-new-notifications"

                enter-active-class="app-notification-enter-active"
                leave-active-class="app-notification-leave-active"
            >
                <template v-for="(item, key) in newNotifications">
                    <Notification
                        show-close=""
                        :item="item"
                        :lang="lang"
                        :key="item.source_id"
                        :style="{
                            top: key * 136 + 'px'
                        }"
                        v-if="!isOpened"
                        @click="onNotificationClick(item)"
                        @close="removeNewNotification(item)"
                    />
                </template>
            </transition-group>

        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import RoundButton from '@/components/RoundButton';
    import CloseButton from '@/components/CloseButton';
    import Wrapper from "@/components/Wrapper";
    import Notification from "@/components/Notification";
    import {
        texts
    } from '@/texts/data';

    export default {
        name: 'app',
        props: {
            api: String,
            lang: {
                type: String,
                default: 'ru'
            },
            app: {
              type: String,
              default: 'front'
            },
            auth: String,
            calendar: String,
            imgUrl: {
                type: String,
                default: '/assets/'
            }
        },
        data() {
            return {
                isLoading: false,
                isOpened: false,
                notificationsCount: 5,
                data: {
                    categories: null,
                    counters: null,
                },
                mainTab: {
                    calendar: null,
                    other: null,
                },
                counts: {
                    screen_1: 0,
                    screen_2: 0,
                    screen_3: 0,
                },
                activeTab: null,
                notifications: [],
                notificationsGrouped: [],
                newNotifications: [],
                newNotificationsCount: 0,
                texts: null,

                page: 1,
                limit: 10,
                lastPage: false,
                noMoreData: false,
                currentDate: moment().date()
            }
        },
        components: {
            Wrapper,
            RoundButton,
            CloseButton,
            Notification
        },
        methods: {
            onToggleOpen() {
                this.isOpened = !this.isOpened;
            },
            onTabClick(id) {
                if (id !== this.activeTab) {
                    this.activeTab = id;
                    this.page = 1;
                    this.noMoreData = false;

                    switch (id) {
                        case 'all':
                            this.mainTab.calendar = null;
                            this.mainTab.other = null;
                            this.sendRequest({
                                "type": 1,
                                "action": 5
                            });
                            break;
                        case 1:
                        case 2:
                        case 3:
                            this.getTabData();
                            break;
                    }
                }
            },
            onNotificationClick(item) {
                this.setReadMessage(item);
                const regExpHost = new RegExp(window.location.hostname);
                if (item.link) {
                    if (regExpHost.test(item.link)) {
                        setTimeout(
                            () => {
                                window.location = item.link;
                            },
                            100
                        );
                    }
                    else {
                        window.open(item.link);
                    }
                }

            },
            onScrollContent(e) {
                const target = e.target;
                const scrollTop = target.scrollTop;
                const height = target.offsetHeight;
                const scrollHeight = target.scrollHeight;

                if (
                    !this.isLoading
                    && !this.noMoreData
                    && (this.activeTab !== 'all')
                ) {
                    if (scrollHeight - scrollTop < height + height / 2) {
                        this.getTabData();

                    }
                }
            },
            onStatusChange(item) {
                if (item.status === 2) {
                    this.setUnReadMessage(item);
                }
                else {
                    this.setReadMessage(item);
                }

            },
            sendRequest(data) {
                if (this.ws) {
                    this.isLoading = true;
                    const send = JSON.stringify(data);
                    if (this.ws.readyState === 1) {
                        this.ws.send(send);
                    }
                    else {
                        this.waitToSend.push(data);
                        this.connectToWS();
                    }

                }
            },
            getTabData() {
                this.sendRequest({
                    "type": 1,
                    "action": 3,
                    "data": {
                        "categories": [],
                        "screens": [
                            this.activeTab
                        ],
                        "page": this.page,
                        "limit": this.limit
                    }
                });
            },
            addNewNotification(item) {
                this.newNotifications.push(item);
                setTimeout(
                    () => {
                        this.removeNewNotification(item);
                    },
                    10000
                );
            },
            addNewNotificationToApp(item) {
                this.newNotificationsCount++;
                let group;
                switch (this.activeTab) {
                    case 'all':
                        group = this.notificationsGrouped.find(g => {
                            return g.tab === item.screen
                        });
                        if (group) {
                            if (group.items.length === 2) {
                                group.items.splice(
                                    1,
                                    1
                                );
                            }
                            group.items.unshift(item);

                            switch (item.screen) {
                                case 1:
                                    this.counts.screen_1++;
                                    break;
                                case 2:
                                    this.counts.screen_2++;
                                    break;
                                case 3:
                                    this.counts.screen_3++;
                                    break;
                            }
                        }
                        break;
                    case 1:
                        if (item.screen === this.activeTab) {
                            this.notificationsGrouped[0].items.unshift(item);
                            this.counts.screen_1++;
                        }
                        break;
                    case 2:
                        if (item.screen === this.activeTab) {
                            this.notificationsGrouped[0].items.unshift(item);
                            this.counts.screen_2++;
                        }
                        break;
                    case 3:
                        if (item.screen === this.activeTab) {
                            this.notificationsGrouped[0].items.unshift(item);
                            this.counts.screen_3++;
                        }
                        break;
                }
            },
            removeNewNotification(item) {
                this.newNotifications.splice(
                    this.newNotifications.findIndex(i => i.id === item.id),
                    1
                );
            },
            connectToWS() {
                this.ws = new WebSocket(`${this.api}?language=${this.lang}&app=${this.app}&auth_key=${this.auth}`);

                this.ws.onopen = () => {

                    this.isLoading = false;
                    if (this.waitToSend.length) {
                        this.waitToSend
                            .forEach(d => this.sendRequest(d));
                        this.waitToSend = [];
                    }
                    else {
                        this.sendRequest({
                            type: 1,
                            action: 4
                        });

                        this.sendRequest({
                            type: 1,
                            action: 5,
                        });
                    }

                };
                this.ws.onmessage = (resp) => {
                    const data = JSON.parse(resp.data);

                    this.processData(data);

                    this.isLoading = false;
                };
                this.ws.onerror = () => {
                    this.isLoading = false;
                };
                this.ws.onclose = () => {
                    this.isLoading = false;
                };

            },
            checkConnectToWS() {
                setInterval(
                    () => {
                        if (this.ws.readyState !== 1) {
                            this.waitToSend.push({});
                            this.connectToWS();
                        }
                    },
                    1000 * 60 * 30
                );

            },
            processData(resp) {
                switch (resp.action) {
                    case 1:
                        // getting new message

                        if (!this.isOpened) {
                            this.addNewNotification(resp.data);
                        }
                        this.addNewNotificationToApp(resp.data);
                        break;
                    case 2:
                        // after send request for read message
                        break;
                    case 3:
                        this.setNotifications(
                            resp.data,
                            'date'
                        );
                        break;
                    case 4:
                        this.setCategories(resp.data);
                        break;
                    case 5:
                        this.setNotifications(
                            resp.data,
                            'category'
                        );
                        this.showLastNotRead();

                        break;
                    default:
                        break;
                }
            },
            setCategories(data) {
                let count = 0;
                this.data.categories = data.categories;
                this.data.counters = data.counters;
                if (this.auth) {
                    this.data.counters.forEach(c => {
                        count += +c.count;
                        switch (c.screen) {
                            case '1':
                                this.counts.screen_1 = c.count;
                                break;
                            case '2':
                                this.counts.screen_2 = c.count;
                                break;
                            case '3':
                                this.counts.screen_3 = c.count;
                                break;
                        }
                    });
                }

                this.newNotificationsCount = count;
            },
            setNotifications(data, type) {
                if (data && data.length) {

                    if (this.page === 1) {
                        this.notifications = data;
                    }
                    else {
                        data.forEach(item => {
                            this.notifications.push(item);
                        });
                    }

                    switch (type) {
                        case 'category':
                            this.notificationsGrouped = this.groupNotificationsByTabs(this.notifications);
                            break;
                        case 'date':
                            this.notificationsGrouped = this.groupNotificationsByDate(this.notifications);
                            this.page++;
                            break;
                    }
                }
                else {
                    if (this.page === 1) {
                        this.notifications = [];
                        this.notificationsGrouped = this.groupNotificationsByDate(this.notifications);
                    }
                    this.noMoreData = true;
                }

            },
            setReadMessage(item) {
                item.status = 2;

                if (this.newNotificationsCount) {
                    this.newNotificationsCount--;
                }
                switch (item.screen) {
                    case 1:
                        if (this.counts.screen_1) {
                            this.counts.screen_1--;
                        }

                        break;
                    case 2:
                        if (this.counts.screen_2) {
                            this.counts.screen_2--;
                        }
                        break;
                    case 3:
                        if (this.counts.screen_3) {
                            this.counts.screen_3--;
                        }
                        break;
                }

                if (this.auth) {

                    this.sendRequest({
                        "type": 1,
                        "action": 2,
                        "data": {
                            "source_ids": [
                                item.source_id
                            ]
                        }
                    });
                }
                else {
                    this.readMessages = [
                        ...this.readMessages,
                        item.source_id
                    ];
                }

            },
            setUnReadMessage(item) {
                item.status = 1;

                this.newNotificationsCount++;

                switch (item.screen) {
                    case 1:
                        this.counts.screen_1++;
                        break;
                    case 2:
                        this.counts.screen_2++;
                        break;
                    case 3:
                        this.counts.screen_3++;
                        break;
                }

                if (this.auth) {
                    this.sendRequest({
                        "type": 1,
                        "action": 6,
                        "data": {
                            "source_ids": [
                                item.source_id
                            ]
                        }
                    });

                }
                else {
                    const readMessages = this.readMessages;
                    if (readMessages.indexOf(item.source_id) !== -1) {
                        readMessages.splice(
                            readMessages.indexOf(item.source_id),
                            1
                        );
                    }
                    this.readMessages = readMessages;
                }
            },
            setReadGroup(group) {
                let notReadCount = 0;
                let ids = [];
                let tab;
                if (this.auth) {
                    group.items.forEach(item => {
                        if (item.status !== 2) {
                            notReadCount++;
                            item.status = 2;
                            ids.push(item.source_id)
                        }

                        tab = item.screen;
                    });
                    if (notReadCount) {
                        this.sendRequest({
                            "type": 1,
                            "action": 2,
                            "data": {
                                "source_ids": ids
                            }
                        });
                    }
                }
                else {
                    const readMessage = new Set(this.readMessages);

                    group
                        .items
                        .forEach(item => {
                            if (item.status !== 2) {
                                notReadCount++;
                                readMessage.add(item.source_id);
                                item.status = 2;
                            }
                            tab = item.screen;
                        });
                    if (notReadCount) {
                        this.readMessages = Array.from(readMessage);
                    }
                }

                if (notReadCount) {
                    this.newNotificationsCount = (
                        this.newNotificationsCount - notReadCount < 0
                            ? 0
                            : this.newNotificationsCount - notReadCount
                    );

                    switch (tab) {
                        case 1:
                            this.counts.screen_1 = (
                                this.counts.screen_1 - notReadCount < 0
                                    ? 0
                                    : this.counts.screen_1 - notReadCount
                            );
                            break;
                        case 2:
                            this.counts.screen_2 = (
                                this.counts.screen_2 - notReadCount < 0
                                    ? 0
                                    : this.counts.screen_2 - notReadCount
                            );
                            break;
                        case 3:
                            this.counts.screen_3 = (
                                this.counts.screen_3 - notReadCount < 0
                                    ? 0
                                    : this.counts.screen_3 - notReadCount
                            );
                            break;
                    }
                }
            },
            groupNotificationsByDate(notification) {
                const format = 'YYYY-MM-DD';
                const groups = [];

                let groupToday = {
                    tab: 'today',
                    title: this.texts.group_today,
                    items: []
                };
                let groupEarlier = {
                    tab: 'earlier',
                    title: this.texts.group_earlier,
                    items: []
                };

                const today = moment()
                    .format(format);

                if (notification) {

                    const readMessage = localStorage.getItem('readMessage')
                        ? JSON.parse(localStorage.getItem('readMessage'))
                        : [];

                    notification
                        .forEach(item => {
                            const category = this.data.categories
                                ? this.data.categories.find(cat => parseInt(cat.id) === item.category_id)
                                : false;

                            if (!item.icon) {
                                item.icon = category
                                    ? category.icon
                                    : '';
                            }

                            if (!this.auth) {

                                item.status = readMessage.includes(item.source_id)
                                    ? 2
                                    : item.status;
                            }

                            if (today === moment.unix(item.sand_at)
                                .format(format)) {
                                groupToday.items.push(item);
                            }
                            else {
                                groupEarlier.items.push(item);
                            }

                        });
                }
                groups.push(groupToday);
                groups.push(groupEarlier);
                return groups;
            },
            groupNotificationsByTabs(notification) {
                const groups = [];
                const groupTabNotifications = {
                    tab: 1,
                    title: this.texts.tab_notifications,
                    items: []
                };
                const groupTabCalendar = {
                    tab: 3,
                    title: this.texts.tab_calendar,
                    items: []
                };
                const groupTabPublications = {
                    tab: 2,
                    title: this.texts.tab_publications,
                    items: []
                };
                if (notification) {
                    const readMessage = this.readMessages;

                    notification
                        .forEach(item => {
                            const category = this.data.categories
                                ? this.data.categories.find(cat => parseInt(cat.id) === item.category_id)
                                : false;
                            if (!item.icon) {
                                item.icon = category
                                    ? category.icon
                                    : '';
                            }

                            if (!this.auth) {
                                item.status = readMessage.includes(item.source_id)
                                    ? 2
                                    : item.status;
                            }

                            switch (item.screen) {
                                case 1:
                                    groupTabNotifications.items.push(item);
                                    break;
                                case 2:
                                    groupTabPublications.items.push(item);
                                    break;
                                case 3:
                                    groupTabCalendar.items.push(item);
                                    break;
                            }
                        });

                    groups.push(groupTabNotifications);
                    groups.push(groupTabCalendar);
                    groups.push(groupTabPublications);

                }
                return groups;
            },

            showLastNotRead() {
                if (!this.wasRunShowLastNotRead) {
                    this.wasRunShowLastNotRead = true;
                    const notReadMessage = [];
                    const wasShow = this.wasShow;
                    this.notifications
                        .sort((a, b) => a.sand_at - b.sand_at)
                        .forEach(item => {
                            if (
                                (
                                    (this.auth && item.status !== 2)
                                    ||
                                    (!this.auth && !this.readMessages.includes(item.source_id))
                                )
                                && notReadMessage.length < 3
                                && !wasShow.includes(item.source_id)
                            ) {
                                notReadMessage.push(item);
                            }
                        });

                    notReadMessage.forEach(item => {
                        this.addNewNotification(item);
                        wasShow.push(item.source_id)
                    });

                    this.wasShow = wasShow;

                }
            }
        },
        computed: {
            wasShow: {
                get() {
                    return this.$cookie.get('showedMessage')
                        ? JSON.parse(this.$cookie.get('showedMessage'))
                        : [];
                },
                set(val) {
                    let host = window.location.hostname;
                    host = host.match(/\w+\.\w+$/i)
                        ? host.match(/\w+\.\w+$/i)[0]
                        : 'localhost';
                    let prevValue = this.$cookie.get('showedMessage');
                    if (prevValue) {
                        prevValue = JSON.parse(prevValue);
                    }
                    else {
                        prevValue = [];
                    }
                    val = val.concat(prevValue);
                    this.$cookie
                        .set(
                            'showedMessage',
                            JSON.stringify(Array.from(new Set(val))),
                            {
                                expires: 9999,
                                domain: host,
                                path: '/'
                            }
                        );
                }
            },
            readMessages: {
                get() {
                    return this.$cookie.get('readMessages')
                        ? JSON.parse(this.$cookie.get('readMessages'))
                        : [];
                },
                set(val) {
                    let host = window.location.hostname;
                    host = host.match(/\w+\.\w+$/i)
                        ? host.match(/\w+\.\w+$/i)[0]
                        : 'localhost';
                    let prevValue = this.$cookie.get('readMessages');
                    if (prevValue) {
                        prevValue = JSON.parse(prevValue);
                    }
                    else {
                        prevValue = [];
                    }
                    val = val.concat(prevValue);
                    this.$cookie
                        .set(
                            'readMessages',
                            JSON.stringify(Array.from(new Set(val))),
                            {
                                expires: 9999,
                                domain: host,
                                path: '/'
                            }
                        );
                }
            }
        },
        created() {
            this.lastId = 22;
            this.isLoading = true;
            this.defaultTab = '';
            this.texts = texts[this.lang];
            Object.freeze(this.texts);
            this.activeTab = 'all';
            this.waitToSend = [];

            this.wasRunShowLastNotRead = false;

            this.connectToWS();

            this.checkConnectToWS();

            window.addEventListener(
                'keyup',
                (e) => {
                    switch (e.code) {
                        case 'Escape':
                            this.isOpened = false;
                            break;
                        /*case 'Digit1':
                            this.sendRequest({
                                "type": 1,
                                "action": 1,
                                "data": {
                                    "source_id": ++this.lastId,
                                    "title": "<p>test ! 1<\/p>\r\n",
                                    "screen": 2,
                                    "category_id": 1,
                                    "message": "<p>test 3333<\/p>\r\n",
                                    "status": 1,
                                    "sand_at": 1566406800
                                },
                                "users": [113192]
                            });
                            break;*/

                    }
                }
            )

        },
        mounted() {

        },
        watch: {}
    }
</script>
<style lang="scss">
    #app-notifications {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        position: fixed;
        right: $app-space;
        bottom: $app-space;
        z-index: 9999;

        @media screen and (max-width: 500px) {
            right: $app-space-mobile;
            bottom: $app-space-mobile;
        }

        * {
            box-sizing: border-box;
        }

        p {
            margin: 0;
        }
    }

    body {
        background: #eee;
        min-height: 100vh;
        margin: 0;
    }
</style>

<style
    lang="scss"
    scoped
>

    .app {
        &-overlay {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: none;

            &-show {
                display: block;
            }
        }

        &-open {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        &-close {
            position: absolute;
            right: 20px;
            top: 20px;
            padding: 0;
        }


        &-header {
            padding: $app-padding;
            height: $app-header-height;

            &-title {
                margin: 0 0 30px 0;
                font-size: 18px;
            }

            &-buttons {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    margin: 0 10px;
                    padding: 0;

                    span {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }
                }
            }
        }

        &-tab-button {
            background: none;
            border: none 0;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            flex-direction: column;
            color: $app-content-color;

            span {
                font-size: 10px;
            }

            i {
                background: $app-tab-button-bg;
                border: $app-tab-button-border;
                width: $app-tab-button-size;
                height: $app-tab-button-size;
                border-radius: $app-tab-button-radius;
                transition: $app-transition-all;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                position: relative;

                img {
                    width: $app-tab-button-size * 0.6;
                    height: $app-tab-button-size * 0.6;
                }

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 30px;
                    height: 30px;
                    color: #00B494;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    transform: translate(-50%, -50%);
                    padding-top: 10px;
                    font-style: normal;
                    font-weight: bold;
                }

                b {
                    font-style: normal;
                    font-weight: normal;
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: flex;
                    width: 22px;
                    height: 22px;
                    justify-content: center;
                    align-items: center;
                    transform: translate(50%, -50%) scale(0);
                    border-radius: 50%;
                    background: $app-accent-color;
                    color: $app-content-color;
                    font-size: 10px;
                    line-height: 1;
                    transition: $app-transition-all;

                    &.app-show {
                        transform: translate(50%, -50%) scale(1);
                    }
                }
            }

            &:hover {
                i {
                    background: $app-tab-button-bg-hover;
                }
            }

            &.active {
                i {
                    background: $app-tab-button-bg-active;
                }

                &:hover {
                    i {
                        background: $app-tab-button-bg-active-hover;
                    }
                }
            }
        }

        &-content {
            padding: $app-padding-x;
            max-height: calc(100vh - #{$app-round-button-margin*2} - #{$app-header-height} - #{$app-padding});
            overflow: auto;

            @media screen and (max-width: 500px) {
                max-height: calc(100vh - #{$app-header-height} - #{$app-padding});
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: $app-scroll-track-bg;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $app-scrollbar-thumb-bg;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $app-scrollbar-thumb-bg-hover;
            }
        }

        &-group {

            &-title {
                font-weight: bold;
                margin: $app-notification-margin;
                position: relative;
            }

            &-content {

            }

            &-status {
                position: absolute;
                right: 10px;
                top: 10px;
                width: 10px;
                height: 10px;
                border: solid 2px #fff;
                border-radius: 50%;
            }
        }

        &-new-notifications {
            position: fixed;
            top: 20px;
            right: 0;
            width: $app-wrapper-width;
            max-width: 80vw;


            .app-notification {
                position: absolute;
                right: 25px;
                box-shadow: 0 0 3px $app-new-notification-border;
            }
        }

        &-more {
            text-align: center;
            padding: 0 0 20px;

            button {
                background: none;
                border: none 0;
                color: $app-content-color;
            }

        }

    }
</style>
