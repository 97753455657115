var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-overlay",class:{'app-overlay-show': _vm.isOpened},on:{"click":function($event){return _vm.onToggleOpen()}}}),_c('div',{class:{'open': _vm.isOpened},attrs:{"id":"app-notifications"}},[_c('Wrapper',{class:{
                'active': _vm.isOpened,
            }},[(_vm.data)?[_c('div',{staticClass:"app-header"},[_c('div',{staticClass:"app-header-title"},[_vm._v(_vm._s(_vm.texts.title))]),_c('div',{staticClass:"app-header-buttons"},[_c('button',{staticClass:"app-tab-button",class:{
                                'active': _vm.activeTab === 'all'
                            },on:{"click":function($event){return _vm.onTabClick('all')}}},[_c('i',[_c('img',{attrs:{"alt":"","src":(_vm.imgUrl + "/1.svg")}})]),_c('span',[_vm._v(_vm._s(_vm.texts.tab_all)+" ")])]),_c('button',{staticClass:"app-tab-button",class:{
                                'active': _vm.activeTab === 1
                            },on:{"click":function($event){return _vm.onTabClick(1)}}},[_c('i',[_c('img',{attrs:{"alt":"","src":(_vm.imgUrl + "/2.svg")}}),_c('b',{class:{'app-show': !!_vm.counts.screen_1}},[_vm._v("\n                                    "+_vm._s(_vm.counts.screen_1 > 99 ? '99+' : _vm.counts.screen_1)+"\n                                ")])]),_c('span',[_vm._v(_vm._s(_vm.texts.tab_notifications)+" ")])]),_c('button',{staticClass:"app-tab-button",class:{
                                'active': _vm.activeTab === 3
                            },on:{"click":function($event){return _vm.onTabClick(3)}}},[_c('i',[_c('img',{attrs:{"alt":"","src":(_vm.imgUrl + "/3.svg")}}),_c('b',{class:{'app-show': !!_vm.counts.screen_3}},[_vm._v("\n                                    "+_vm._s(_vm.counts.screen_3 > 99 ? '99+' : _vm.counts.screen_3)+"\n                                ")]),_c('span',[_vm._v("\n                                    "+_vm._s(_vm.currentDate)+"\n                                ")])]),_c('span',[_vm._v(_vm._s(_vm.texts.tab_calendar)+" ")])]),_c('button',{staticClass:"app-tab-button",class:{
                                'active': _vm.activeTab === 2
                            },on:{"click":function($event){return _vm.onTabClick(2)}}},[_c('i',[_c('img',{attrs:{"alt":"","src":(_vm.imgUrl + "/4.svg")}}),_c('b',{class:{'app-show': !!_vm.counts.screen_2}},[_vm._v("\n                                    "+_vm._s(_vm.counts.screen_2 > 99 ? '99+' : _vm.counts.screen_2)+"\n                                ")])]),_c('span',[_vm._v(_vm._s(_vm.texts.tab_publications)+" ")])])])]),_c('div',{staticClass:"app-content",on:{"scroll":_vm.onScrollContent}},_vm._l((_vm.notificationsGrouped),function(group){return _c('div',{key:group.tab,staticClass:"app-group"},[(group.items.length)?[_c('div',{staticClass:"app-group-title"},[_vm._v("\n                                "+_vm._s(group.title)+"\n                                "),_c('i',{staticClass:"app-group-status",on:{"click":function($event){return _vm.setReadGroup(group)}}})]),_c('div',{staticClass:"app-group-content"},[_c('transition-group',{attrs:{"tag":"div","enter-active-class":"app-notification-to-app-enter-active","leave-active-class":"app-notification-to-app-leave-active"}},_vm._l((group.items),function(item){return _c('Notification',{key:item.source_id,attrs:{"item":item,"lang":_vm.lang},on:{"click":_vm.onNotificationClick,"changeStatus":_vm.onStatusChange}})}),1)],1),(_vm.activeTab==='all')?_c('div',{staticClass:"app-more"},[_c('button',{on:{"click":function($event){return _vm.onTabClick(group.tab)}}},[_vm._v(_vm._s(_vm.texts.open_more))])]):_vm._e()]:_vm._e()],2)}),0)]:_vm._e(),_c('CloseButton',{staticClass:"app-close",class:{
                'hide': !_vm.isOpened,
            },on:{"click":_vm.onToggleOpen}})],2),_c('RoundButton',{staticClass:"app-open",class:{
            'app-hide': _vm.isOpened,
            'app-loading': _vm.isLoading
        },attrs:{"number":_vm.newNotificationsCount,"img-url":_vm.imgUrl},on:{"click":_vm.onToggleOpen}}),_c('transition-group',{staticClass:"app-new-notifications",attrs:{"tag":"div","enter-active-class":"app-notification-enter-active","leave-active-class":"app-notification-leave-active"}},[_vm._l((_vm.newNotifications),function(item,key){return [(!_vm.isOpened)?_c('Notification',{key:item.source_id,style:({
                        top: key * 136 + 'px'
                    }),attrs:{"show-close":"","item":item,"lang":_vm.lang},on:{"click":function($event){return _vm.onNotificationClick(item)},"close":function($event){return _vm.removeNewNotification(item)}}}):_vm._e()]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }